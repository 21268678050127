import { connect, MapStateToProps } from 'react-redux'
import { Props } from './UserGarageButton'
import isUserGarageButtonVisibleSelector
    from '../../redux/selectors/garage/isUserGarageButtonVisibleSelector'
import configurationIsBuildableSelector
    from '../../redux/selectors/configuration/configurationIsBuildableSelector'
import { storeUserGarageVehicle } from '../../redux/actions/app/userGarage.actions'

type StateProps = Pick<Props, 'isVisible' | 'configurationIsBuildable'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    isVisible: isUserGarageButtonVisibleSelector(state),
    configurationIsBuildable: configurationIsBuildableSelector(state),
})

type DispatchProps = Pick<Props, 'storeVehicle'>

const mapDispatchToProps: DispatchProps = {
    storeVehicle: storeUserGarageVehicle,
}

export default connect(mapStateToProps, mapDispatchToProps)
