import { createAction, createAsyncAction } from 'typesafe-actions'
import UserGarageInfo from '../../../entities/UserGarageInfo'
import VehicleGarageUpdateInfo from '../../../entities/VehicleGarageUpdateInfo'

export const setUserGarageInfoState = createAction('DOC / UserGarageInfo / set state')<UserGarageInfo>()

export const setUserGarageVehicleNotes = createAction('DOC / Vehicle / set vehicle notes')<VehicleGarageUpdateInfo>()

export const fetchUserGarageInfo = createAction('CMD / UserGarageInfo / fetch')()

export const storeUserGarageVehicle = createAction('CMD / UserGarageInfo / store vehicle')()

export const deleteUserGarageVehicle = createAction('CMD / UserGarageInfo / delete vehicle')<string>()

export const updateUserGarageVehicleNotes = createAction('CMD / UserGarageInfo / update vehicle')<VehicleGarageUpdateInfo>()

export const compareUserGarageVehicleCodes = createAction('CMD / string[] / compare vehicle codes')<string[]>()

export const storeUserGarageVehicleAsync = createAsyncAction(
    'EVT / UserGarageInfo / store vehicle request',
    'EVT / UserGarageInfo / store vehicle success',
    'EVT / UserGarageInfo / store vehicle failure',
)<void, void, Error>()

export const fetchUserGarageInfoAsync = createAsyncAction(
    'EVT / UserGarageInfo / fetch request',
    'EVT / UserGarageInfo / fetch success',
    'EVT / UserGarageInfo / fetch failure',
)<void, UserGarageInfo, Error>()

export const deleteUserGarageVehicleAsync = createAsyncAction(
    'EVT / UserGarageInfo / delete vehicle request',
    'EVT / UserGarageInfo / delete vehicle success',
    'EVT / UserGarageInfo / delete vehicle failure',
)<string, void, Error>()

export const updateUserGarageVehicleNotesAsync = createAsyncAction(
    'EVT / UserGarageInfo / update vehicle request',
    'EVT / UserGarageInfo / update vehicle success',
    'EVT / UserGarageInfo / update vehicle failure',
)<void, VehicleGarageUpdateInfo, Error>()
