import { connect, MapStateToProps } from 'react-redux'
import { Props } from './UserGarage'
import userGarageVehiclesSelector from '../../redux/selectors/garage/userGarageVehiclesSelector'
import { fetchUserGarageInfo } from '../../redux/actions/app/userGarage.actions'
import userGarageRequestIsPendingSelector from '../../redux/selectors/pending-request/userGarageRequestIsPendingSelector'

type StateProps = Pick<Props, 'userGarageVehicles' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    userGarageVehicles: userGarageVehiclesSelector(state),
    isLoading: userGarageRequestIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'loadUserGarageInfo'>

const mapDispatchToProps: DispatchProps = {
    loadUserGarageInfo: fetchUserGarageInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)
