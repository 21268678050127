import React, { FunctionComponent } from 'react'
import style from './userGarageVehicleItem.css'
import useTranslation from '../../hooks/useTranslation'
import { ImagesPreview } from '../../base-components'
import EraseIcon from '../../assets/media/icons/erase.svg'
import NoticeIcon from '../../assets/media/icons/file.svg'
import EditIcon from '../../assets/media/icons/edit.svg'
import ComparisonIcon from '../../assets/media/icons/garage-compare.svg'

const UserGarageVehicleItemsPlaceholder: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <>
            {[...Array(3)].map((element, index) => (
                <div className={style.vehicleItem} key={index}>
                    <section>
                        {t('loading')}
                        <div className={style.vehicleDetailsPlaceholder}>
                            <ImagesPreview
                                images={[]}
                                isHidden={false}
                                isLoading={false}
                            />
                        </div>
                    </section>
                    <footer className={style.footerUtilities}>
                        <div className={style.comparisonSelectorPlaceholder}>
                            <div className={style.comparisonSelectorBoxPlaceholder}>{t('loading')}</div>
                            <button className={style.compareButtonPlaceholder}>
                                <ComparisonIcon className={style.icon}/>
                            </button>
                        </div>
                        <div
                            className={`${style.footerButtons} ${style.footerButtonsPlaceholders}`}>
                            <button><EraseIcon className={style.icon}/></button>
                            <button><NoticeIcon className={style.icon}/></button>
                            <button><EditIcon className={style.icon}/></button>
                        </div>
                    </footer>
                </div>
            ))}
        </>
    )
}

export default UserGarageVehicleItemsPlaceholder
