import React, { FunctionComponent, useState } from 'react'
import Select from 'react-select'
import style from './userGarageComparisonSelector.css'
import Vehicle from '../../entities/Vehicle'
import { CSSObject } from 'styled-components'
import ComparisonIcon from '../../assets/media/icons/garage-compare.svg'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    vehicles: Vehicle[]
    currentVehicleCode: string
    compareVehicleCodes: (vehicleCodes: string[]) => void
}

const UserGarageComparisonSelector: FunctionComponent<Props> = ({vehicles, currentVehicleCode, compareVehicleCodes}) => {
    const [selected, setSelected] = useState<{ value: string; slotNum: string }[]>([])
    const { t } = useTranslation()
    const MAX_SELECTIONS = 2

    const options = vehicles
        .filter((vehicle) => vehicle.vehicleCode !== currentVehicleCode)
        .map((vehicle) => ({
            value: vehicle.vehicleCode,
            slotNum: vehicle.slotNum,
            modelName: vehicle.modelName,
        }))

    const customStyles = {
        container: (base: CSSObject) => ({
            ...base,
            minWidth: '250px',
        }),
        control: (base: CSSObject, {isDisabled}) => ({
            ...base,
            border: isDisabled
                ? '1px solid var(--color-grey-20)'
                : '1px solid var(--color-grey-40)',
            boxShadow: 'none',
            borderRadius: '0',
            '&:hover': {
                border: '1px solid var(--color-grey-40)',
            },
        }),
        menu: (base: CSSObject) => ({
            ...base,
            zIndex: 10,
            width: '410px',
            borderRadius: '0',
        }),
        option: (base: CSSObject, {isSelected, isDisabled}) => ({
            ...base,
            margin: 0,
            marginTop: '4px',
            backgroundColor: isDisabled
                ? 'transparent'
                : isSelected
                    ? 'var(--color-grey-10)'
                    : 'transparent',
            color: isDisabled ? 'var(--color-grey-40)' : 'var(--color-black)',
            ':hover': {
                backgroundColor: isDisabled ? 'var(--color-grey-20)' : 'var(--color-grey-10)',
            },
        }),
        placeholder: (base: CSSObject) => ({
            ...base,
            color: 'gray',
        }),
        multiValue: (base: CSSObject) => ({
            ...base,
            backgroundColor: 'var(--color-grey-20)',
            color: 'var(--color-black)',
            fontWeight: 'var(--font-weight-bold)',
            borderRadius: '0',
        }),
        multiValueRemove: (base: CSSObject) => ({
            ...base,
            '&:hover': {
                backgroundColor: 'var(--color-grey-40)',
                color: 'var(--color-black)',
            },
        }),
    }

    const formatOptionLabel = (option: { slotNum: string; modelName: string }, context: {
        context: string
    }) => {
        return context.context === 'menu' ? (
            <div className={style.menuOption}>
                <span className={style.slotNum}>{option.slotNum}</span>
                <span>{option.modelName}</span>
            </div>
        ) : (
            <span>{option.slotNum}</span>
        )
    }

    const handleChange = (selectedOptions: any): void => {
        if (selectedOptions.length <= MAX_SELECTIONS) {
            setSelected(selectedOptions || [])
        }
    }

    const isOptionDisabled = (option: { value: string; slotNum: string }) => {
        return selected.length === MAX_SELECTIONS && !selected.some((item) => item.value === option.value)
    }

    const handleCompareVehicleCodes = () => {
        const vehicleCodes = selected.map((option) => option.value)
        vehicleCodes.length < MAX_SELECTIONS && Array.from({length: MAX_SELECTIONS - vehicleCodes.length}, () => vehicleCodes.push(''))
        compareVehicleCodes([currentVehicleCode, ...vehicleCodes])
    }

    return (
        <div className={style.selectorContainer}>
            <Select
                options={options}
                value={selected}
                isMulti
                onChange={handleChange}
                placeholder={t('garage.userGarage.compareSelectorPlaceholder')}
                styles={customStyles}
                getOptionValue={(option) => option.value}
                formatOptionLabel={formatOptionLabel}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isSearchable={false}
                isOptionDisabled={isOptionDisabled}
                isDisabled={!options || options.length === 0}
                menuPlacement='auto'
            />
            <button
                type="button"
                className={style.compareButton}
                onClick={handleCompareVehicleCodes}
            >
                <ComparisonIcon className={style.icon}/>
                {t('garage.userGarage.compareButtonLabel')}
            </button>
        </div>
    )
}

export default UserGarageComparisonSelector
