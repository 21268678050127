import { createSelector } from 'reselect'
import { CarlineFilter } from '../../../entities/CarlineFiltersData'
import carlineFilterGroupsSelector from './carlineFilterGroupsSelector'

const carlineFiltersSelector = createSelector(
    carlineFilterGroupsSelector,
    (carlineGroups): CarlineFilter[] => (
        carlineGroups.reduce<CarlineFilter[]>((accumulator, carlineGroup) => (
            accumulator.concat(carlineGroup.carlines)
        ), [])
    ),
)

export default carlineFiltersSelector
