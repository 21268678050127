import React, { FunctionComponent } from 'react'
import Modal from '../../modal'
import style from '../userGarageVehicleItem.css'
import Vehicle from '../../../entities/Vehicle'
import useTranslation from '../../../hooks/useTranslation'

export type Props = {
    handleDeleteConfirmation: () => void
    onClose: () => void
    vehicle: Vehicle
    isVisible: boolean
}

const UserGarageDeleteModal: FunctionComponent<Props> = (props) => {
    const { isVisible, vehicle, handleDeleteConfirmation, onClose } = props
    const { t } = useTranslation()

    return (
        <Modal isVisible={isVisible}>
            <div className={style.modalContent}>
                <div className={style.deleteModalTitleContainer}>
                    <h2 className={style.modalTitle}>{t('garage.userGarage.deleteModalTitle')}</h2>
                    <span className={style.modalSlotNum}>{vehicle.slotNum}</span>
                </div>
                <p>{t('garage.userGarage.deleteModalMessage')}</p>
                <div className={style.modalButtons}>
                    <button
                        className={style.modalButton}
                        onClick={handleDeleteConfirmation}
                    >
                        {t('garage.userGarage.deleteModalConfirm')}
                    </button>
                    <button
                        className={style.modalButton}
                        onClick={onClose}
                    >
                        {t('garage.userGarage.deleteModalCancel')}
                    </button>
                </div>
            </div>
        </Modal>)
}

export default UserGarageDeleteModal
