import { push } from 'connected-react-router'
import { stringify } from 'query-string'
import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    getAddConfigurationUrl,
    getConfigurationByVehiclecodeApiUrl,
    getConfigurationUrl,
    getRemoveConfigurationUrl,
    getResetConfigurationApiUrl,
} from '../../../constants/apiUrls'
import { ROUTE_OVERVIEW } from '../../../constants/routes'
import { resetAudiExclusiveDataState, updateAudiExclusiveSelection } from '../../actions/app/audiExclusiveApp.actions'
import { selectCarline } from '../../actions/app/carlineSelection.actions'
import { fetchCart } from '../../actions/app/cart.actions'
import {
    fetchConfiguration,
    fetchConfigurationAsync,
    getConfigurationByVehicleCode,
    getConfigurationByVehicleCodeAsync,
    putConfiguration,
    putConfigurationAsync,
    resetConfiguration,
    resetConfigurationAsync,
    setConfigurationDataState,
} from '../../actions/app/configuration.actions'
import { acceptRescueConflictSolution } from '../../actions/app/conflictSolution.actions'
import { fetchEquipmentGroups } from '../../actions/app/equipmentGroups.actions'
import { resetVehicleCodeInState } from '../../actions/app/initParameters.actions'
import { resetConfigurationProcess } from '../../actions/app/reset.actions'
import { resetVehicleCodeStatus } from '../../actions/app/vehicleCode.actions'
import { fetchVisualization } from '../../actions/app/visualization.actions'
import { ApiRequest } from '../../apiRequest'
import selectedCarlineIdSelector from '../../selectors/carlines/selectedCarlineIdSelector'
import excludedPartsSelector from '../../selectors/excludedPartsSelector'
import isAudiExclusiveEnabledSelector from '../../selectors/feature-scope/isAudiExclusiveEnabledSelector'
import { resetRecommendationsForModelData } from '../../actions/app/recommendation.actions'

// eslint-disable-next-line max-len
const configurationDataMiddleware =
    (apiRequest: ApiRequest): Middleware =>
    (store) =>
    (next) =>
    (action) => {
        next(action)
        const { dispatch, getState } = store

        if (isActionOf(fetchConfiguration, action)) {
            apiRequest(
                {
                    options: {
                        url: getConfigurationUrl(),
                        method: 'GET',
                    },
                    asyncActions: fetchConfigurationAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(fetchConfigurationAsync.success, action)) {
            const configurationData = action.payload

            dispatch(setConfigurationDataState(configurationData))

            const selectedCarlineId = selectedCarlineIdSelector(getState())

            if (configurationData.carlineId && selectedCarlineId !== configurationData.carlineId) {
                dispatch(
                    selectCarline(configurationData.carlineId, {
                        forwardToModelPage: false,
                    }),
                )
            }
        }

        if (isActionOf(putConfiguration, action)) {
            const { id, method } = action.payload
            const getUrl = method === 'add' ? getAddConfigurationUrl : getRemoveConfigurationUrl

            apiRequest(
                {
                    options: {
                        url: getUrl(id),
                        method: 'PATCH',
                    },
                    asyncActions: putConfigurationAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(putConfigurationAsync.success, action)) {
            const configurationData = action.payload
            const isAudiExclusiveEnabled = isAudiExclusiveEnabledSelector(getState())
            // const selectedCarlineId = selectedCarlineIdSelector(getState())

            dispatch(setConfigurationDataState(configurationData))

            if (!['CONFLICT', 'MODEL_CONFLICT'].includes(configurationData.configurationState)) {
                if (isAudiExclusiveEnabled) {
                    dispatch(updateAudiExclusiveSelection())
                } else {
                    dispatch(fetchEquipmentGroups(undefined))
                    dispatch(fetchCart())
                    dispatch(fetchVisualization())
                }
            }

            // Is Rescue Conflict?
            if (
                (action as any)?.meta?.causedBy &&
                isActionOf(acceptRescueConflictSolution, (action as any)?.meta?.causedBy)
            ) {
                dispatch(resetVehicleCodeInState())
                dispatch(resetVehicleCodeStatus())
                dispatch(fetchConfiguration())
                dispatch(push(ROUTE_OVERVIEW))
            }
        }

        if (isActionOf(resetConfiguration, action)) {
            apiRequest(
                {
                    options: {
                        url: getResetConfigurationApiUrl(),
                        method: 'POST',
                    },
                    asyncActions: {
                        request: resetConfigurationAsync.request,
                        success: action.meta?.onSuccess || resetConfigurationAsync.success,
                        failure: action.meta?.onFailure || resetConfigurationAsync.failure,
                    },
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(resetConfigurationAsync.success, action)) {
            dispatch(resetConfigurationProcess())
            dispatch(resetAudiExclusiveDataState())
            dispatch(resetRecommendationsForModelData())
        }

        if (isActionOf(getConfigurationByVehicleCode, action)) {
            const vehicleCode = action.payload

            const excludeParts = excludedPartsSelector(getState())
            const requestParams = stringify(
                { excludeParts },
                {
                    skipNull: true,
                },
            )

            apiRequest(
                {
                    options: {
                        url: getConfigurationByVehiclecodeApiUrl(vehicleCode, requestParams),
                        method: 'GET',
                    },
                    asyncActions: {
                        request: getConfigurationByVehicleCodeAsync.request,
                        success: action.meta?.onSuccess || getConfigurationByVehicleCodeAsync.success,
                        failure: action.meta?.onFailure || getConfigurationByVehicleCodeAsync.failure,
                    },
                    causedBy: action,
                },
                dispatch,
            )
        }
    }

export default configurationDataMiddleware
