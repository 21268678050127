import { CarlineId } from '../entities/Carline'
import VehicleCode from '../entities/VehicleCode'
import { PriceViewFlag } from '../entities/PriceViewFlag'
import config from '../config'

const { API_URL } = config

export const API_URL_ENTRY = `${API_URL}/api/v1/entry`
export const API_URL_CARLINES = `${API_URL}/api/v1/catalog/carlines`
export const API_URL_MODELS = `${API_URL}/api/v1/catalog/models`
export const API_URL_CONFIGURATION = `${API_URL}/api/v1/configuration`
export const API_URL_TECHNICAL_DATA = `${API_URL}/api/v1/technicalData`
export const API_URL_EFFICIENCY_DATA = `${API_URL}/api/v1/efficiencyData`
export const API_URL_CART = `${API_URL}/api/v1/cart`
export const API_URL_EQUIPMENT = `${API_URL}/api/v1/catalog/equipment`
export const API_URL_VISUALIZATION = `${API_URL}/api/v1/visualization`
export const API_URL_VEHICLECODE = `${API_URL}/api/v1/vehiclecode`
export const API_URL_PR_STRING = `${API_URL}/api/v1/prString`
export const API_URL_FINANCING = `${API_URL}/api/v1/financing`
export const API_URL_USER_GARAGE_VEHICLES = `${API_URL}/api/v1/garage/vehicles`
export const API_URL_USER_GARAGE_VEHICLE = `${API_URL}/api/v1/garage/vehicle`
export const API_URL_LAST_CONFIGURATIONS = `${API_URL}/api/v1/garage/lastConfigurations`
export const API_URL_AUDI_EXCLUSIVE = `${API_URL}/api/v1/audiExclusive`
export const API_URL_DIGITAL_GIVEAWAY_LINK = `${API_URL}/api/v1/digitalGiveaway/link`
export const API_URL_BUILDABILITY_TYPE = `${API_URL}/api/v1/settings/buildabilityType`
export const API_URL_COMPARISON_CARLINE_STRUCTURE = `${API_URL}/api/v1/catalog/comparisonCarlineStructure`
export const API_URL_COMPARE_MODELS = `${API_URL}/api/v1/compare/models`
export const API_URL_COMPARE_CODES = `${API_URL}/api/v1/compare/vehicleCodes`
export const API_URL_CARLINE_FILTERS =  `${API_URL_CARLINES}/filters`
export const API_URL_RECOMMENDATIONS = `${API_URL}/api/v1/recommendations`

export const getEntryApiUrl = (contextId: string, params: string): string => `${API_URL_ENTRY}/${contextId}?${params}`

export const getCarlinesApiUrl = (): string => `${API_URL_CARLINES}`

export const getCarlineFiltersApiUrl = (): string => `${API_URL_CARLINE_FILTERS}`

export const getModelsApiUrl = (carlineId: CarlineId): string => `${API_URL_MODELS}?carlineId=${carlineId}`

export const getConfigurationUrl = (): string => API_URL_CONFIGURATION

export const getAddConfigurationUrl = (triggerId: string): string => `${API_URL_CONFIGURATION}/add?triggerId=${triggerId}`

export const getConflictSolutionResolveUrl = (adopt: 'ACCEPT' | 'REJECT' | 'CANCEL'): string => `${API_URL_CONFIGURATION}/add?adopt=${adopt}`

export const getRemoveConfigurationUrl = (triggerId: string): string => `${API_URL_CONFIGURATION}/remove?triggerId=${triggerId}`

export const getConfigurationByVehiclecodeApiUrl = (vehiclecode: VehicleCode, params: string): string => `${API_URL_CONFIGURATION}/${vehiclecode}?${params}`

export const getEquipmentApiUrl = (): string => `${API_URL_EQUIPMENT}`

export const getFetchCartApiUrl = (): string => `${API_URL_CART}`

export const getPutCartApiUrl = (priceViewFlag: PriceViewFlag): string => `${API_URL_CART}?priceViewFlag=${priceViewFlag}`

export const getTechnicalDataApiUrl = (configurationString: string): string => `${API_URL_TECHNICAL_DATA}?configurationString=${configurationString}`

export const getVisualizationApiUrl = (): string => `${API_URL_VISUALIZATION}`

export const getCreateVehiclecodeApiUrl = (): string => `${API_URL_VEHICLECODE}`

export const loadConfigurationByVehiclecodeApiUrl = (vehiclecode: VehicleCode): string => `${API_URL_VEHICLECODE}/${vehiclecode}`

export const loadConfigurationByPrStringApiUrl = (prString: string): string => `${API_URL_PR_STRING}?prString=${prString}`

export const getResetConfigurationApiUrl = (): string => `${API_URL_CONFIGURATION}/start`

export const getConfigurationSummaryApiUrl = (): string => `${API_URL_CONFIGURATION}/summary`

export const getEfficiencyDataApiUrl = (): string => `${API_URL_EFFICIENCY_DATA}/formatted`

export const getAudiExclusiveApiUrl = (): string => `${API_URL_AUDI_EXCLUSIVE}`

export const getDigitalGiveawayLinkUrl = (vehicleCode: string, withFlightCamera: boolean, dealerId: string): string => {
    const param = dealerId ? '?dealerId=' + dealerId : ''
    return `${API_URL_DIGITAL_GIVEAWAY_LINK}/${vehicleCode}/${withFlightCamera}${param}`
}

export const getChangeBuildabilityTypeApiUrl = (buildabilityType: string): string => `${API_URL_BUILDABILITY_TYPE}/${buildabilityType}`

export const getFetchBuildabilityTypeApiUrl = (): string => API_URL_BUILDABILITY_TYPE

export const getComparisonCarlineStructureApiUrl = (): string => `${API_URL_COMPARISON_CARLINE_STRUCTURE}`

export const getCompareModelsApiUrl = (): string => `${API_URL_COMPARE_MODELS}`

export const getCompareCodesApiUrl = (): string => `${API_URL_COMPARE_CODES}`

export const getLastConfigurationsApiUrl = (): string => API_URL_LAST_CONFIGURATIONS

export const getUserGarageVehiclesApiUrl = (): string => `${API_URL_USER_GARAGE_VEHICLES}`

export const getUserGarageVehicleApiUrl = (vehicleCode: string): string => `${API_URL_USER_GARAGE_VEHICLE}/${vehicleCode}`

export const getRecommendationsApiUrl = (): string => `${API_URL_RECOMMENDATIONS}`

export const getModelsWithRecommendationsApiUrl = (): string => `${API_URL_RECOMMENDATIONS}/models`
