import UserGarageInfo from '../../entities/UserGarageInfo'
import { createReducer } from 'typesafe-actions'
import {
    setUserGarageInfoState,
    setUserGarageVehicleNotes,
} from '../actions/app/userGarage.actions'
import Vehicle from '../../entities/Vehicle'

export const defaultState: UserGarageInfo = {
    storedVehicles: [],
}

const userGarageInfoReducer = createReducer(defaultState)
    .handleAction(setUserGarageInfoState, (state, action) => action.payload)
    .handleAction(setUserGarageVehicleNotes, (state, action) => {
        const {notes, vehicleCode} = action.payload
        const updatedVehicles = state.storedVehicles.map((vehicle: Vehicle) =>
            vehicle.vehicleCode === vehicleCode ? {...vehicle, notes} : vehicle)

        return {...state, storedVehicles: updatedVehicles}
    })

export default userGarageInfoReducer
