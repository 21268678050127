import { connect, MapStateToProps } from 'react-redux'
import RecommendationsComponent, { Props } from './RecommendationsComponent'
import recommendationsForModelDataSelector from '../../redux/selectors/recommendations/recommendationsForModelDataSelector'
import { selectEquipment } from '../../redux/actions/app/equipmentGroups.actions'
import { openInfoModal } from '../../redux/actions/app/infoModal.actions'

type StateProps = Pick<
    Props,
    'recommendationsData'
>

type DispatchProps = Pick<Props, 'selectEquipment' | 'openInfoModal'>

const mapDispatchToProps: DispatchProps = {
    selectEquipment,
    openInfoModal,
}

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    recommendationsData: recommendationsForModelDataSelector(state),
})

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsComponent)
