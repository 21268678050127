import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import Modal, { ModalClosingButton } from '../modal'
import Tabbox, { Tab } from '../tabbox'
import Gallery from '../gallery'
import style from './infoModal.css'
import { InfoModalData } from '../../entities/InfoModal'
import TechnicalDataTab from '../technical-data-tab'
import PackageTab from '../package-tab'
import WheelEfficiencyLabel from '../wheel-efficiency-label'
import VideoGallery from '../gallery-video'

export type Props = InfoModalData & {
    closeInfoModal: Function
    wheelEfficiencyLabelIsEnabled: boolean
}

const InfoModal: FunctionComponent<Props> = (props) => {
    const {
        modalIsOpen,
        title,
        media,
        activeMedia,
        equipmentKey,
        closeInfoModal,
        technicalDataConfigurationString,
        content,
        wheelEfficiencyLabelIsEnabled,
        tabs,
        displaySingleTab,
    } = props
    const { t } = useTranslation()

    const closeButtonClicked = (): void => {
        closeInfoModal()
    }

    const equipmentKeyElement = equipmentKey
        ? <span className={style.equipmentKey}>{equipmentKey}</span>
        : null

    const createWheelEfficiencyLabelTab = (): Tab | null => {
        const wheelEfficiencyLabelMedia = media.filter((mediaItem) => mediaItem.type === 'efficiencyLabel')

        if (wheelEfficiencyLabelMedia.length === 0) {
            return null
        }

        const infoLabels = wheelEfficiencyLabelMedia.map((mediaItem) => {
            const labelImgLinkObj = mediaItem.efficiencyLabel.find((efficiencyLabelItem) => efficiencyLabelItem.id === 'label-image-link')
            const dataSheetLinkObj = mediaItem.efficiencyLabel.find((efficiencyLabelItem) => efficiencyLabelItem.id === 'data-sheet-link')

            return {
                imgUrl: labelImgLinkObj?.path || '',
                dataSheetUrl: dataSheetLinkObj?.path || '',
            }
        })

        return {
            id: 'wheelEfficiencyLabel',
            label: t('infoModal.wheelEfficiencyLabel'),
            content: <WheelEfficiencyLabel wheelEfficiencyInfoLabels={infoLabels} />,
        }
    }

    const createTextTab = (): Tab | null => {
        const textMedia = media
            .filter((mediaItem) => mediaItem.type === 'text')
            .filter((mediaItem) => !mediaItem.forced)
            .map((textItem) => `<div class="${style.textItem} ${textItem.id} ${textItem.forced ? style.forced : ''}">${textItem.text}</div>`)

        if (textMedia.length === 0) {
            return null
        }

        return {
            id: 'text',
            label: t('infoModal.text'),
            content: (
                <div className={style.texts}>
                    <Markup content={textMedia.join('')} />
                </div>
            ),
        }
    }

    const createForcedImageTab = (): Tab | null => {
        const imageMedia = media
            .filter(({ type }) => type === 'image')
            .filter((mediaItem) => mediaItem.forced)
            .map(({ path }) => ({
                src: path,
                width: undefined,
                height: undefined,
            }))

        if (imageMedia.length === 0) {
            return null
        }

        return {
            id: 'forcedImage',
            label: t('infoModal.forcedImage'),
            content: (
                <Gallery
                    thumbnails={imageMedia}
                    images={imageMedia}
                    miwiImagesUrls={[imageMedia[0].src]}
                    forced={true}
                />
            ),
            isActive: activeMedia === 'forcedImage',
        }
    }

    const createForcedTextTab = (): Tab | null => {
        const textMedia = media
            .filter((mediaItem) => mediaItem.type === 'text')
            .filter((mediaItem) => mediaItem.forced)
            .map((textItem) => {
                const label = textItem.label ? `<strong>${textItem.label}</strong><br>` : ''
                const text = textItem.text ? textItem.text.replaceAll("\r\n", "<br>") : textItem.text
                return `<div class="${style.textItem} ${textItem.id} ${textItem.forced ? style.forced : ''}">${label}${text}</div>`
            })

        if (textMedia.length === 0) {
            return null
        }

        return {
            id: 'forcedText',
            label: t('infoModal.forcedText'),
            content: (
                <div className={style.texts}>
                    <Markup content={textMedia.join('')} />
                </div>
            ),
        }
    }

    const createImageTab = (): Tab | null => {
        const imageMedia = media
            .filter(({ type }) => type === 'image')
            .filter((mediaItem) => !mediaItem.forced)
            .map(({ path }) => ({
                src: path,
                width: undefined,
                height: undefined,
            }))

        if (imageMedia.length === 0) {
            return null
        }

        return {
            id: 'image',
            label: t('infoModal.image'),
            content: (
                <Gallery
                    thumbnails={imageMedia}
                    images={imageMedia}
                    miwiImagesUrls={[imageMedia[0].src]}
                />
            ),
            isActive: activeMedia === 'image',
        }
    }

    const createVideoTab = (): Tab | null => {
        const videoMedia = media
            .filter(({ type }) => type === 'video')
            .map(({ path }) => ({
                src: path,
                width: undefined,
                height: undefined,
            }))

        if (videoMedia.length === 0) {
            return null
        }

        return {
            id: 'video',
            label: 'Videos',
            content: (
                <VideoGallery
                    videos={videoMedia}
                />
            ),
            isActive: activeMedia === 'video',
        }
    }

    const createTechnicalDataTab = (): Tab | null => {
        if (technicalDataConfigurationString === undefined) {
            return null
        }

        return {
            id: 'technicalData',
            label: t('infoModal.technicalData'),
            content: <TechnicalDataTab configurationString={technicalDataConfigurationString} />,
        }
    }

    const createTabs = (): Tab[] => {
        if (tabs === undefined || tabs.length === 0) {
            return []
        }

        return tabs
    }

    const createPackageTab = (): Tab | null => {
        if (!content || content.length === 0) {
            return null
        }

        return {
            id: 'package',
            label: t('infoModal.package.tab'),
            content: <PackageTab content={content} />,
        }
    }

    const tabboxTabs: Tab[] = modalIsOpen ? [
        wheelEfficiencyLabelIsEnabled ? createWheelEfficiencyLabelTab() : null,
        createForcedImageTab(),
        createForcedTextTab(),
        createTextTab(),
        createImageTab(),
        createVideoTab(),
        createPackageTab(),
        createTechnicalDataTab(),
        ...createTabs(),
    ].filter(Boolean).map((tab) => ({
        ...tab,
        content: <div className={style.infoTabWrapper}>{tab?.content}</div>,
    })) : []

    return (
        <Modal
            isVisible={modalIsOpen}
            onClose={closeButtonClicked}
            contentClassName={style.modalContent}
        >
            <div className={style.closingButtonWrapper}>
                <ModalClosingButton onClick={closeButtonClicked} className={style.closingButton} />
            </div>
            <article className={style.container}>
                <h2 className={style.title}>
                    <Markup content={title} />
                    {equipmentKeyElement}
                </h2>
                <Tabbox tabs={tabboxTabs} displaySingleTab={displaySingleTab} />
            </article>
        </Modal>
    )
}

export default InfoModal
