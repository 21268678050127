import { createSelector } from 'reselect'
import { API_URL_USER_GARAGE_VEHICLE, API_URL_USER_GARAGE_VEHICLES } from '../../../constants/apiUrls'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const userGarageRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean =>
        requestIsPending(pendingRequests, API_URL_USER_GARAGE_VEHICLE) ||
        requestIsPending(pendingRequests, API_URL_USER_GARAGE_VEHICLES),
)
export default userGarageRequestIsPendingSelector
