import { createAction, createAsyncAction } from 'typesafe-actions'
import CarlineData from '../../../entities/CarlineData'
import CarlineFiltersData from '../../../entities/CarlineFiltersData'

export const fetchCarlineData = createAction('CMD / CarlineData / fetch')()

export const fetchCarlineFiltersData = createAction('CMD / CarlineFiltersData / fetch')()

export const fetchCarlineDataAsync = createAsyncAction(
    'EVT / CarlineData / fetch request',
    'EVT / CarlineData / fetch success',
    'EVT / CarlineData / fetch failure',
)<undefined, CarlineData, Error>()

export const fetchCarlineFiltersDataAsync = createAsyncAction(
    'EVT / CarlineFiltersData / fetch request',
    'EVT / CarlineFiltersData / fetch success',
    'EVT / CarlineFiltersData / fetch failure',
)<undefined, CarlineFiltersData, Error>()

export const setCarlineDataState = createAction('DOC / CarlineData / set state')<CarlineData>()

export const setCarlineFiltersDataState = createAction('DOC / CarlineFiltersData / set state')<CarlineFiltersData>()
