import { createSelector } from 'reselect'
import { API_URL_LAST_CONFIGURATIONS } from '../../../constants/apiUrls'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const lastConfigurationsRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_LAST_CONFIGURATIONS),
)

export default lastConfigurationsRequestIsPendingSelector
