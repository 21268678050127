import React, { FunctionComponent } from 'react'
import { Markup } from 'interweave'
import useTranslation from '../../hooks/useTranslation'
import Model, { ModelAttributeId } from '../../entities/Model'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import ModelTableSubAttr from './ModelTableSubAttr'
import { Checkbox } from '../form'
import style from './modelsTable.css'
import { Td, Tr } from '../table'
import DiscountItemIcon from '../../assets/media/icons/discount.svg'
import NoteTable from '../note-table'

export type Props = {
    model: Model
    viewAttributes: ModelAttributeId[]
    selectModel: Function
    deselectModel: Function
    openInfoModal: Function
    remarkLabels: Record<string, string>
}

const ModelsTableRow: FunctionComponent<Props> = (props) => {
    const {
        model,
        viewAttributes,
        selectModel,
        deselectModel,
        openInfoModal,
        remarkLabels,
    } = props

    const { t, i18n } = useTranslation()

    const translateValue = (value: string | undefined | null): string => {
        if (value !== null && value !== undefined) {
            return i18n.exists(value)
                ? t(value)
                : value
        }

        return ''
    }

    const {
        id,
        name,
        price,
        attributes,
        media,
        mbvId,
        selected,
        incentiveModelInfo,
    } = model

    const infoIsForced = media.some((mediaItem) => mediaItem.forced)

    const infoButtonClicked = (): void => {
        openInfoModal({
            title: name,
            media,
            technicalDataConfigurationString: mbvId,
        })
    }

    const discountInfoButtonClicked = (): void => {
        const tabs = incentiveModelInfo.tabsInfo.map((tabInfo) => {
            const tabels = tabInfo.notes.map((note) => (
                <NoteTable data={note} key={note.headline} />
            ))

            const tabLabel = translateValue(tabInfo.name)

            return {
                id: tabInfo.name,
                label: tabLabel,
                content: tabels,
            }
        })

        const modalTitle = translateValue('incentive.popupTitle')

        openInfoModal({
            title: `${modalTitle} ${name}`,
            wheelEfficiencyLabelIsEnabled: false,
            media: [],
            tabs,
            displaySingleTab: true,
        })
    }

    const changeHandler = (): void => {
        if (selected) {
            deselectModel(id)
        } else {
            const infoModal = infoIsForced ? {
                title: name,
                media,
                technicalDataConfigurationString: mbvId,
            } : undefined

            selectModel(id, { infoModal })
        }
    }

    const attributeCells = viewAttributes ? viewAttributes.map((attributeViewId) => {
        const attributeForView = attributes.find((attribute) => attribute.id === attributeViewId)

        if (!attributeForView) {
            return <Td key={attributeViewId} className={style.attributeCol} />
        }

        const { value, formattedValue, id: attributeId } = attributeForView
        const languageKeyForAttributeValue = `attributes.valueLabels.${attributeId}.${value}`
        const translatedValue = i18n.exists(languageKeyForAttributeValue)
            ? t(languageKeyForAttributeValue)
            : value

        return (
            <Td key={attributeViewId} className={style.attributeCol}>
                {formattedValue || translatedValue}
                {remarkLabels[attributeForView.valueRemarkRefId]}
            </Td>
        )
    }) : []

    const checkboxId = `checkbox-${id}`

    let discountComponent = null

    if (incentiveModelInfo && incentiveModelInfo.name) {
        let discountTypeText

        if (incentiveModelInfo.name) {
            discountTypeText = translateValue(incentiveModelInfo.name)
        }

        discountComponent = (
            <div className={style.discountContainer}>
                <DiscountItemIcon className={style.discountContainerIcon} />
                <span className={style.discountContainerText}>{discountTypeText}</span>
                <InfoButton openModal={discountInfoButtonClicked} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                    <InfoI isForced={infoIsForced} />
                </InfoButton>
            </div>
        )
    }

    return (
        <Tr key={id} className={style.row}>
            <Td className={style.nameCol}>
                <div className={style.nameColInner}>
                    <span>
                        <Checkbox
                            id={checkboxId}
                            name="model"
                            onChange={changeHandler}
                            checked={selected}
                            className={style.checkbox}
                            data-cy={`model-select-${id.replace(/\\/g, '.')}`}
                        />
                    </span>
                    <span>
                        <label className={style.label} htmlFor={checkboxId} data-id={id} title={id}>
                            {name}
                        </label>
                        <p className={style.subtext}>
                            <ModelTableSubAttr
                                attribute={attributes.find((attr) => attr.id === 'consumption')}
                                remarkLabels={remarkLabels}
                            />
                            <ModelTableSubAttr
                                attribute={attributes.find((attr) => attr.id === 'emission')}
                                remarkLabels={remarkLabels}
                            />
                        </p>
                    </span>
                </div>
            </Td>
            {attributeCells}
            <Td className={style.priceCol}>
                <div className={style.priceContainer}>
                    <Markup content={price} noWrap />
                </div>
                <InfoButton openModal={infoButtonClicked} dataCy={`info-button-${id.replace(/\\/g, '.')}`}>
                    <InfoI isForced={infoIsForced} />
                </InfoButton>
                {discountComponent}
            </Td>
        </Tr>
    )
}

export default ModelsTableRow
