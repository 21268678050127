import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchCarlineData,
    fetchCarlineDataAsync,
    fetchCarlineFiltersData,
    fetchCarlineFiltersDataAsync,
    setCarlineDataState, setCarlineFiltersDataState,
} from '../../actions/app/carlineData.actions'
import { getCarlineFiltersApiUrl, getCarlinesApiUrl } from '../../../constants/apiUrls'
import { ApiRequest } from '../../apiRequest'
import CarlineData from '../../../entities/CarlineData'
import CarlineFiltersData from '../../../entities/CarlineFiltersData'

const mergeCarlineData = (
  carlineData: CarlineData,
  carlineFiltersData: CarlineFiltersData,
): CarlineData => {
  const filterMap = new Map(
    carlineFiltersData.carlineGroups.flatMap((group) =>
      group.carlines.map((carline) => [carline.id, carline.attributes]),
    ),
  )

  const mergedCarlineGroups = carlineData.carlineGroups.map((group) => ({
    ...group,
    carlines: group.carlines.map((carline) => ({
      ...carline,
      attributes: filterMap.get(carline.id) || {},
    })),
  }))

  return { carlineGroups: mergedCarlineGroups }
}

const carlineDataMiddleware = (apiRequest: ApiRequest): Middleware => (store) => {
  let carlineData: CarlineData | null = null
  let carlineFiltersData: CarlineFiltersData | null = null

  return (next) => (action) => {
    next(action)
    const { dispatch } = store

    if (isActionOf(fetchCarlineData, action)) {
      apiRequest(
        {
          options: {
            url: getCarlinesApiUrl(),
            method: 'GET',
          },
          asyncActions: fetchCarlineDataAsync,
          causedBy: action,
        },
        dispatch,
      )
    }

    if (isActionOf(fetchCarlineDataAsync.success, action)) {
      carlineData = action.payload
      dispatch(fetchCarlineFiltersData())

      if (carlineFiltersData) {
        const mergedData = mergeCarlineData(carlineData, carlineFiltersData)
        dispatch(setCarlineDataState(mergedData))
      }
    }

    if (isActionOf(fetchCarlineFiltersData, action)) {
      apiRequest(
        {
          options: {
            url: getCarlineFiltersApiUrl(),
            method: 'GET',
          },
          asyncActions: fetchCarlineFiltersDataAsync,
          causedBy: action,
        },
        dispatch,
      )
    }

    if (isActionOf(fetchCarlineFiltersDataAsync.success, action)) {
      carlineFiltersData = action.payload

      if (carlineData) {
        const mergedData = mergeCarlineData(carlineData, carlineFiltersData)
        dispatch(setCarlineFiltersDataState(carlineFiltersData))
        dispatch(setCarlineDataState(mergedData))
      }
    }
  }
}

export default carlineDataMiddleware
