import React, { FunctionComponent, useState } from 'react'
import Vehicle from '../../entities/Vehicle'
import style from './userGarageVehicleItem.css'
import { Markup } from 'interweave'
import { ImagesPreview } from '../../base-components'
import EraseIcon from '../../assets/media/icons/erase.svg'
import NoticeIcon from '../../assets/media/icons/file.svg'
import EditIcon from '../../assets/media/icons/edit.svg'
import UserGarageDeleteModal from './modals/UserGarageDeleteModal'
import UserGarageUpdateNotesModal from './modals/UserGarageUpdateNotesModal'
import UserGarageComparisonSelector
    from '../user-garage-comparison-selector/UserGarageComparisonSelector'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    vehicle: Vehicle
    vehicles: Vehicle[]
    loadVehicleCode: (vehicleCode: string) => void
    deleteVehicle: (vehicleCode: string) => void
    updateVehicleNotes: ({ vehicleCode, notes }) => void
    compareVehicleCodes: (vehicleCodes: string[]) => void
}

const UserGarageVehicleItem: FunctionComponent<Props> = (props) => {
    const {vehicle, vehicles, loadVehicleCode, deleteVehicle, updateVehicleNotes, compareVehicleCodes} = props
    const {t} = useTranslation()

    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [updateNotesModalOpen, setUpdateNotesModalOpen] = useState(false)

    const handleDeleteVehicle = () => {
        setDeleteModalOpen(false)
        deleteVehicle(vehicle.vehicleCode)
    }

    const handleUpdateNotes = (notes: string) => {
        setUpdateNotesModalOpen(false)
        updateVehicleNotes({vehicleCode: vehicle.vehicleCode, notes})
    }

    const handleEditVehicle = () => {
        loadVehicleCode(vehicle.vehicleCode)
    }

    const imageActiveIndex = vehicle.images
        ? vehicle.images.indexOf(
            vehicle.images.find((image) => image.id === 'exterior_side-middle') || vehicle.images[0],
        )
        : 0

    const equipmentsElements = vehicle.equipmentsInfo && vehicle.equipmentsInfo.length !== 0 ? (
        <>
            <span className={style.label}><strong>{t('garage.userGarage.equipmentsLabel')}:</strong></span>
            <ul className={style.listOfDetails}>
                {vehicle.equipmentsInfo.map((equipment) => (
                    <li key={equipment}>{equipment}</li>
                ))}
            </ul>
        </>
    ) : null

    const accessoriesElements = vehicle.accessoriesInfo && vehicle.accessoriesInfo.length !== 0 ? (
        <>
            <span className={style.label}><strong>{t('garage.userGarage.accessoriesLabel')}:</strong></span>
            <ul className={style.listOfDetails}>
                {vehicle.accessoriesInfo.map((accessory) => (
                    <li key={accessory}>{accessory}</li>
                ))}
            </ul>
        </>
    ) : null

    const vehiclePriceElement = vehicle.totalPrice && vehicle.totalPrice.value
        ? (
            <div className={style.vehiclePrice}>
            <span className={style.label}>
            {t(
                vehicle.totalPrice?.priceType?.toUpperCase().includes('BRUTTO')
                    ? 'garage.userGarage.totalPriceLabel.brutto'
                    : 'garage.userGarage.totalPriceLabel.netto',
            )}
                :
            </span>
                <span>{vehicle.totalPrice?.value}</span>
            </div>
        )
        : null

    const notesElement = vehicle.notes ? (<div className={style.notice}>
            <span className={style.label}>{t('garage.userGarage.userNoteLabel')}:</span>
            <span>{vehicle.notes}</span>
        </div>)
        : null

    const exteriorColor = vehicle.exteriorColorName
        ? <>
            <span className={style.label}>{t('garage.userGarage.exteriorColorLabel')}:</span>
            <span><Markup content={vehicle.exteriorColorName}/></span>
        </>
        : null

    const interiorColor = vehicle.interiorColorName
        ? <>
            <span className={style.label}>{t('garage.userGarage.interiorColorLabel')}:</span>
            <span>{vehicle.interiorColorName}</span>
        </>
        : null

    return (
        <div className={style.vehicleItem} key={vehicle.vehicleCode}>
            <header className={style.header}>
                <span className={style.headerSlot}>{vehicle.slotNum}</span>
                <span className={style.label}>{vehicle.modelName}</span>
            </header>
            {notesElement}
            <section className={style.vehicleDetailsWrapper}>
                <div className={style.vehicleDetails}>
                    <span className={style.label}>{t('garage.userGarage.vehicleCodeLabel')}:</span>
                    <span>{vehicle.vehicleCode}</span>

                    {exteriorColor}
                    {interiorColor}
                    {equipmentsElements}
                    {accessoriesElements}
                </div>
                <div className={style.vehicleAdditionalDetails}>
                    <ImagesPreview
                        images={vehicle.images || []}
                        isHidden={false}
                        isLoading={false}
                        defaultActiveIndex={imageActiveIndex}
                    />
                    {vehiclePriceElement}
                </div>
            </section>
            <footer className={style.footerUtilities}>
                <UserGarageComparisonSelector
                    vehicles={vehicles}
                    currentVehicleCode={vehicle.vehicleCode}
                    compareVehicleCodes={compareVehicleCodes}
                />
                <div className={style.footerButtons}>
                    <button onClick={() => setDeleteModalOpen(true)}>
                        <EraseIcon className={style.icon}/>{t('garage.userGarage.deleteButtonLabel')}
                    </button>
                    <button onClick={() => setUpdateNotesModalOpen(true)}>
                        <NoticeIcon className={style.icon}/>{t('garage.userGarage.noteButtonLabel')}
                    </button>
                    <button onClick={handleEditVehicle}>
                        <EditIcon className={style.icon}/>{t('garage.userGarage.editButtonLabel')}
                    </button>
                </div>
            </footer>
            <UserGarageDeleteModal
                isVisible={deleteModalOpen}
                handleDeleteConfirmation={handleDeleteVehicle}
                onClose={() => setDeleteModalOpen(false)}
                vehicle={vehicle}
            />
            <UserGarageUpdateNotesModal
                isVisible={updateNotesModalOpen}
                vehicle={vehicle}
                handleUpdateConfirmation={handleUpdateNotes}
                onClose={() => setUpdateNotesModalOpen(false)}
            />
        </div>
    )
}

export default UserGarageVehicleItem
