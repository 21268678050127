import { translate } from '../hooks/useTranslation'

const getFilterTranslation = (key: string, params?: {}): string => {
    const result = translate(key, params)

    if (result === null) {
        const keyParts = key.split('.')
        return keyParts.length > 3 ? keyParts.slice(3).join('.') : keyParts[keyParts.length - 1]
    }

    return result
}

export default getFilterTranslation
