import { createReducer } from 'typesafe-actions'
import {RecommendationsData} from '../../entities/RecommendationsData'
import {resetModelsWithRecommendationsData, setModelsWithRecommendationsData} from '../actions/app/recommendation.actions'

export type ModelsWithRecommendationsDataState = RecommendationsData

export const defaultState: ModelsWithRecommendationsDataState = {
    carlines: [],
}

const modelsWithRecommendationsDataReducer = createReducer(defaultState)
    .handleAction(setModelsWithRecommendationsData, (state, action) => action.payload)
    .handleAction(resetModelsWithRecommendationsData, () => defaultState)

export default modelsWithRecommendationsDataReducer
