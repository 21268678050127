import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { ApiRequest } from '../../apiRequest'
import { getModelsWithRecommendationsApiUrl, getRecommendationsApiUrl} from '../../../constants/apiUrls'
import {
    fetchRecommendationsForModel,
    fetchRecommendationsForModelAsync,
    fetchModelsWithRecommendations,
    fetchModelsWithRecommendationsAsync,
    setModelsWithRecommendationsData, setRecommendationsForModelData,
} from '../../actions/app/recommendation.actions'

const recommendationsMiddleware =
    (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
        next(action)
        const {dispatch} = store

        if (isActionOf(fetchModelsWithRecommendations, action)) {
            apiRequest({
                options: {
                    url: getModelsWithRecommendationsApiUrl(),
                    method: 'GET',
                },
                asyncActions: fetchModelsWithRecommendationsAsync,
                causedBy: action,
            }, dispatch)
        }

        if (isActionOf(fetchRecommendationsForModel, action)) {
            apiRequest({
                options: {
                    url: getRecommendationsApiUrl(),
                    method: 'GET',
                },
                asyncActions: fetchRecommendationsForModelAsync,
                causedBy: action,
            }, dispatch)
        }

        if (isActionOf(fetchModelsWithRecommendationsAsync.success, action)) {
            const modelsWithRecommendationsData = action.payload

            dispatch(setModelsWithRecommendationsData(modelsWithRecommendationsData))
        }

        if (isActionOf(fetchRecommendationsForModelAsync.success, action)) {
            const recommendationsForModelData = action.payload

            dispatch(setRecommendationsForModelData(recommendationsForModelData))
        }
    }

export default recommendationsMiddleware
