import { createReducer } from 'typesafe-actions'
import { ModelRecommendationsData } from '../../entities/ModelRecommendationsData'
import { setRecommendationsForModelData, resetRecommendationsForModelData } from '../actions/app/recommendation.actions'

export type RecommendationsForModelDataState = ModelRecommendationsData

export const defaultState: RecommendationsForModelDataState = {
    recommendations: [],
}

const recommendationsForModelDataReducer = createReducer(defaultState)
    .handleAction(setRecommendationsForModelData, (state, action) => action.payload)
    .handleAction(resetRecommendationsForModelData, () => defaultState)

export default recommendationsForModelDataReducer
