import { createSelector } from 'reselect'
import FilterOption from '../../../entities/FilterOption'
import filterListSelector from '../filterListSelector'
import filtersSelector from '../filtersSelector'
import carlineFiltersSelector from './carlineFiltersSelector'

const carlineFilterOptionsSelector = createSelector(
    filterListSelector,
    carlineFiltersSelector,
    filtersSelector,
    (filterList, carlineFilters, appliedFilters): FilterOption[] => {
        const filterOptions = filterList
            .map((filter) => {
                const values = carlineFilters.reduce((accumulator, carline) => {
                    if (carline.attributes && carline.attributes[filter.attribute]) {
                        const carlineAttributes = carline.attributes[filter.attribute]

                        carlineAttributes.forEach((carlineAttribute) => {
                            if (
                                carlineAttribute !== undefined
                                && !accumulator.includes(carlineAttribute)
                            ) {
                                accumulator.push(carlineAttribute)
                            }
                        })
                    }
                    return accumulator
                }, [] as (string | number)[])

                return {
                    attribute: filter.attribute,
                    type: filter.type,
                    values,
                    activeValues: appliedFilters[filter.attribute] || [],
                }
            })
            .filter((filterOption) => filterOption.values.length > 0)

        return filterOptions
    },
)

export default carlineFilterOptionsSelector
