import React, { FunctionComponent } from 'react'
import Select from 'react-select'
import { CSSObject } from 'styled-components'
import { OptionType } from '../../entities/OptionType'
import style from './checkboxSelectDropdown.css'
import { Checkbox } from '../form'

export type Props = {
    options: OptionType[]
    onChange: (selectedOptions: OptionType[]) => void
    value: OptionType[]
    placeholder: string
    isClearable?: boolean
    isLoading?: boolean
    styles?: any
    activeValues?: (string | number)[]
}

const CheckboxSelectDropdown: FunctionComponent<Props> = (props) => {
    const {
        options,
        onChange,
        value,
        placeholder,
        isClearable = true,
        isLoading = false,
        styles = {},
        activeValues = [],
    } = props

    const customStyles = {
        container: (base: CSSObject) => ({
            ...base,
            marginBottom: '15px',
        }),
        option: (styles: CSSObject, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused
                ? 'var(--color-grey-15)'
                : 'transparent',
            color: isSelected ? 'var(--color-black)' : 'var(--color-grey-80)',
            ':hover': {
                backgroundColor: 'var(--color-grey-15)',
                color: 'var(--color-black)',
            },
        }),
    }

    const mergedStyles = {
        ...styles,
        ...customStyles,
        option: (base: CSSObject, state: any) => ({
            ...customStyles.option(base, state),
            ...(styles?.option ? styles.option(base, state) : {}),
        }),
    }

    const formatOptionLabel = ({ label, value }: OptionType) => (
        <div className={style.optionLabel}>
            <Checkbox
                checked={props.value.some((selected) => selected.value === value)}
                readOnly
            />
            <span>{label}</span>
        </div>
    )

    const selectedCount = activeValues.length
    const countCircle = selectedCount > 0 ? (
        <div className={style.selectedCounter}>
            {selectedCount}
        </div>
    ) : null

    const customPlaceholder = (
        <div className={style.placeHolder}>
            {placeholder}
            {countCircle}
        </div>
    )

    return (
        <Select
            isMulti
            options={options}
            onChange={onChange}
            value={value}
            placeholder={customPlaceholder}
            isClearable={isClearable}
            className={style.dropdown}
            classNamePrefix={style.dropdown}
            styles={mergedStyles}
            isLoading={isLoading}
            formatOptionLabel={formatOptionLabel}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            controlShouldRenderValue={false}
        />
    )
}

export default CheckboxSelectDropdown
