import { connect, MapStateToProps } from 'react-redux'
import { Props } from './UserGarageVehicleItem'
import userGarageVehiclesSelector from '../../redux/selectors/garage/userGarageVehiclesSelector'
import {
    compareUserGarageVehicleCodes,
    deleteUserGarageVehicle,
    updateUserGarageVehicleNotes,
} from '../../redux/actions/app/userGarage.actions'
import {loadConfigurationByVehicleCode} from '../../redux/actions/app/vehicleCode.actions'

type StateProps = Pick<Props, 'vehicles'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    vehicles: userGarageVehiclesSelector(state),
})

type DispatchProps = Pick<Props, 'deleteVehicle' | 'updateVehicleNotes' | 'loadVehicleCode' | 'compareVehicleCodes'>

const mapDispatchToProps: DispatchProps = {
    loadVehicleCode: loadConfigurationByVehicleCode,
    deleteVehicle: deleteUserGarageVehicle,
    updateVehicleNotes: updateUserGarageVehicleNotes,
    compareVehicleCodes: compareUserGarageVehicleCodes,
}

export default connect(mapStateToProps, mapDispatchToProps)
