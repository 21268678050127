import { createSelector } from 'reselect'
import configurationDataSelector from '../configuration/configurationDataSelector'
import modelsWithRecommendationsDataSelector from './modelsWithRecommendationsDataSelector'

const modelHasRecommendationsSelector = createSelector(
  [configurationDataSelector, modelsWithRecommendationsDataSelector],
  (configurationData, modelsWithRecommendations) => {
    const modelId = configurationData.modelId.split('-')[0]
    return modelsWithRecommendations.carlines.some(carline =>
      carline.models.some(model => model.id.includes(modelId)),
    )
  },
)

export default modelHasRecommendationsSelector
