import { createSelector } from 'reselect'

import initParametersSelector from '../initParametersSelector'
import featureScopesSelector from '../feature-scope/featureScopesSelector'

const isLastConfigurationsButtonVisibleSelector = createSelector(
    featureScopesSelector,
    initParametersSelector,
    (featureScopes, initParameters): boolean =>
        featureScopes.includes('glc') &&
        !!initParameters.dealerId &&
        initParameters.dealerId.trim() !== '',
)

export default isLastConfigurationsButtonVisibleSelector
