import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchCartAsync,
    setCartState,
    putCartAsync,
    fetchCart,
    putCart,
} from '../../actions/app/cart.actions'
import { getFetchCartApiUrl, getPutCartApiUrl } from '../../../constants/apiUrls'
import configuredCarlineIdSelector from '../../selectors/carlines/configuredCarlineIdSelector'
import { fetchModels } from '../../actions/app/models.actions'
import { fetchEquipmentGroups } from '../../actions/app/equipmentGroups.actions'
import { ApiRequest } from '../../apiRequest'
import {fetchRecommendationsForModel, resetRecommendationsForModelData} from '../../actions/app/recommendation.actions'
import configurationIsBuildableSelector from '../../selectors/configuration/configurationIsBuildableSelector'
import modelHasRecommendationsSelector from '../../selectors/recommendations/modelHasRecommendationsSelector'

const cartMiddleware = (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(fetchCart, action)) {
        apiRequest({
            options: {
                url: getFetchCartApiUrl(),
                method: 'GET',
            },
            asyncActions: fetchCartAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(fetchCartAsync.success, action)) {
        const cart = action.payload
        const state = getState()
        const carIsBuildable = configurationIsBuildableSelector(state)
        const modelHasVwnAiRecommendations = modelHasRecommendationsSelector(state)

        dispatch(setCartState(cart))

        if (carIsBuildable && modelHasVwnAiRecommendations) {
            dispatch(fetchRecommendationsForModel())
        } else {
            dispatch(resetRecommendationsForModelData())
        }
    }

    if (isActionOf(putCart, action)) {
        const priceViewFlag = action.payload

        apiRequest({
            options: {
                url: getPutCartApiUrl(priceViewFlag),
                method: 'PUT',
            },
            asyncActions: putCartAsync,
            causedBy: action,
        }, dispatch)
    }

    if (isActionOf(putCartAsync.success, action)) {
        const cart = action.payload
        const carlineId = configuredCarlineIdSelector(getState())
        const state = getState()
        const carIsBuildable = configurationIsBuildableSelector(state)
        const modelHasVwnAiRecommendations = modelHasRecommendationsSelector(state)

        dispatch(setCartState(cart))
        dispatch(fetchModels(carlineId))
        dispatch(fetchEquipmentGroups())

        if (carIsBuildable && modelHasVwnAiRecommendations) {
            dispatch(fetchRecommendationsForModel())
        } else {
            dispatch(resetRecommendationsForModelData())
        }
    }
}

export default cartMiddleware
