import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import UserGarageButtonIcon from '../../assets/media/icons/user-garage-button.svg'
import CartboxButton from '../cartbox-button'

export type Props = {
    isVisible: boolean
    configurationIsBuildable: boolean
    storeVehicle: () => {}
}

const UserGarageButton: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation()

    const { isVisible, configurationIsBuildable, storeVehicle } = props

    if (!isVisible) {
        return null
    }

    const garageButtonClicked = (): void => {
        storeVehicle()
    }

    return (
        <CartboxButton
            onClick={garageButtonClicked}
            disabled={!configurationIsBuildable}
            icon={<UserGarageButtonIcon />}
            label={t('garage.userGarage.cartboxButtonLabel')}
        />
    )
}

export default UserGarageButton
