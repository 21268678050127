import { createSelector } from 'reselect'

import initParametersSelector from '../initParametersSelector'
import featureScopesSelector from '../feature-scope/featureScopesSelector'

const isUserGarageButtonVisibleSelector = createSelector(
    featureScopesSelector,
    initParametersSelector,
    (featureScopes, initParameters): boolean =>
        featureScopes.includes('glc') &&
        !!initParameters.carstoreUserId &&
        initParameters.carstoreUserId.trim() !== '',
)

export default isUserGarageButtonVisibleSelector
