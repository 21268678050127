import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Vehicle from '../../entities/Vehicle'
import useOnMount from '../../hooks/useOnMount'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import style from './userGarage.css'
import UserGarageVehicle from '../user-garage-vehicle'
import UserGarageVehicleItemsPlaceholder
    from '../user-garage-vehicle/UserGarageVehicleItemsPlaceholder'

export type Props = {
    userGarageVehicles: Vehicle[]
    loadUserGarageInfo: () => {}
    isLoading: boolean
}

const UserGarage: FunctionComponent<Props> = (props) => {
    const { userGarageVehicles, loadUserGarageInfo, isLoading } = props
    const { t } = useTranslation()

    useOnMount(() => {
        loadUserGarageInfo && loadUserGarageInfo()
    })

    const vehicleElements = isLoading && (!userGarageVehicles || userGarageVehicles.length === 0)
        ? <UserGarageVehicleItemsPlaceholder/>
        : userGarageVehicles.map((vehicle) => (
            <UserGarageVehicle
                key={vehicle.vehicleCode}
                vehicle={vehicle}
            />
        ))

    return (
        <div className={style.container}>
            <section className={style.headerContainer}>
                <h1 className={style.title}>
                    {t('garage.userGarage.heading')}
                </h1>
            </section>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <div className={style.content}>
                    {vehicleElements}
                </div>
            </LoadingIndicatorWrapper>
        </div>
)
}

export default UserGarage
