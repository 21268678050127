import { ApiRequest } from '../../apiRequest'
import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import {
    fetchUserGarageInfo,
    storeUserGarageVehicle,
    setUserGarageInfoState,
    deleteUserGarageVehicle,
    updateUserGarageVehicleNotes,
    setUserGarageVehicleNotes,
    compareUserGarageVehicleCodes,
    fetchUserGarageInfoAsync,
    storeUserGarageVehicleAsync,
    deleteUserGarageVehicleAsync,
    updateUserGarageVehicleNotesAsync,
} from '../../actions/app/userGarage.actions'
import {
    API_URL_USER_GARAGE_VEHICLES,
    getUserGarageVehicleApiUrl,
    getUserGarageVehiclesApiUrl,
} from '../../../constants/apiUrls'
import { push } from 'connected-react-router'
import { ROUTE_COMPARE, ROUTE_GARAGE } from '../../../constants/routes'
import { addPendingRequest, removePendingRequest } from '../../actions/core/pendingRequests.actions'
import {
    fetchCodeComparisonResultData,
    resetComparisonResultData,
} from '../../actions/app/comparisonResultData.actions'
import ComparisonData from '../../../entities/ComparisonData'
import { setComparisonData } from '../../actions/app/comparisonData.actions'

const userGarageMiddleware =
    (apiRequest: ApiRequest): Middleware => (store) => (next) => (action) => {
        next(action)

        const {dispatch} = store

        if (isActionOf(fetchUserGarageInfo, action)) {
            apiRequest(
                {
                    options: {
                        url: getUserGarageVehiclesApiUrl(),
                        method: 'GET',
                    },
                    asyncActions: fetchUserGarageInfoAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(storeUserGarageVehicle, action)) {
            dispatch(addPendingRequest(`${API_URL_USER_GARAGE_VEHICLES}/store`, { causedBy: storeUserGarageVehicle() }))
            apiRequest(
                {
                    options: {
                        url: getUserGarageVehiclesApiUrl(),
                        method: 'POST',
                    },
                    asyncActions: storeUserGarageVehicleAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(deleteUserGarageVehicle, action)) {
            const vehicleCode = action.payload
            apiRequest(
                {
                    options: {
                        url: getUserGarageVehicleApiUrl(vehicleCode),
                        method: 'DELETE',
                    },
                    asyncActions: deleteUserGarageVehicleAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(updateUserGarageVehicleNotes, action)) {
            const {vehicleCode, notes} = action.payload
            apiRequest(
                {
                    options: {
                        url: getUserGarageVehicleApiUrl(vehicleCode),
                        method: 'PATCH',
                        body: JSON.stringify({notes}),
                    },
                    asyncActions: updateUserGarageVehicleNotesAsync,
                    causedBy: action,
                },
                dispatch,
            )
        }

        if (isActionOf(compareUserGarageVehicleCodes, action)) {
            const vehicleCodes: string[] = action.payload || []
            const comparisonData: ComparisonData = {
                type: 'codes',
                onlyDifferent: false,
                codes: vehicleCodes.map(code => ({code})),
                models: [{carlineId: '', modelId: ''}, {carlineId: '', modelId: ''}, {carlineId: '', modelId: ''}],
            }
            dispatch(setComparisonData(comparisonData))
            dispatch(resetComparisonResultData())
            dispatch(push(ROUTE_COMPARE))
            vehicleCodes.filter(Boolean).length > 1 && dispatch(fetchCodeComparisonResultData(comparisonData))
        }

        if (isActionOf(updateUserGarageVehicleNotesAsync.success, action)) {
            const { notes, vehicleCode} = action.payload
            dispatch(setUserGarageVehicleNotes({notes, vehicleCode}))
        }

        if (isActionOf(storeUserGarageVehicleAsync.success, action)) {
            dispatch(removePendingRequest(`${API_URL_USER_GARAGE_VEHICLES}/store`, { causedBy: storeUserGarageVehicle() }))
            dispatch(push(ROUTE_GARAGE))
        }

        if (isActionOf(storeUserGarageVehicleAsync.failure, action)) {
            dispatch(removePendingRequest(`${API_URL_USER_GARAGE_VEHICLES}/store`, { causedBy: storeUserGarageVehicle() }))
        }

        if (isActionOf(fetchUserGarageInfoAsync.success, action)) {
            dispatch(setUserGarageInfoState(action.payload))
        }

        if (isActionOf(deleteUserGarageVehicleAsync.success, action)) {
            dispatch(fetchUserGarageInfo())
        }
    }

export default userGarageMiddleware
