import React, { FunctionComponent, useState } from 'react'
import RenderImagesPreview from '../../components/render-images-preview/RenderImagesPreview'
import RenderImage from '../../entities/RenderImage'

export type Props = {
    images: RenderImage[]
    isHidden?: boolean
    isLoading?: boolean
    defaultActiveIndex?: number
}

export const ImagesPreview: FunctionComponent<Props> = (
    {
        images,
        isHidden = false,
        isLoading = false,
        defaultActiveIndex = 0,
    }) => {
    const [activeIndex, setActiveIndex] = useState(defaultActiveIndex)

    return (
        <RenderImagesPreview
            renderImages={images}
            activeRenderImgIdx={activeIndex}
            setActiveRenderImgIdx={setActiveIndex}
            isHidden={isHidden}
            isLoading={isLoading}
            renderImagesOrdering={[]}
        />
    )
}
