import React, { FunctionComponent, useState } from 'react'
import Modal, { ModalClosingButton } from '../../modal'
import style from '../userGarageVehicleItem.css'
import Vehicle from '../../../entities/Vehicle'
import useTranslation from '../../../hooks/useTranslation'

export type Props = {
    handleUpdateConfirmation: (notes: string) => void
    onClose: () => void
    isVisible: boolean
    vehicle: Vehicle
}

const UserGarageDeleteModal: FunctionComponent<Props> = (props) => {
    const { isVisible, handleUpdateConfirmation, onClose, vehicle } = props
    const { t } = useTranslation()

    const [notes, setNotes] = useState(vehicle.notes ?? '')

    const handleSaveNotes = () => {
        handleUpdateConfirmation(notes)
    }

    return (
        <Modal isVisible={isVisible}>
            <ModalClosingButton onClick={onClose}/>
            <div className={style.modalContent}>
                <h2 className={style.modalTitle}>{t('garage.userGarage.noteModalTitle')}</h2>
                <div className={style.modalReferenceDetails}>
                    <span className={style.modalSlotNum}>{vehicle.slotNum}</span>
                    <span className={style.modalModelName}>{vehicle.modelName}</span>
                </div>
                <textarea
                    className={style.modalTextArea}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    onFocus={(e) => {
                        const length = e.target.value.length
                        e.target.setSelectionRange(length, length)
                    }}
                    autoFocus
                />
                <div className={style.modalButtons}>
                    <button
                        type="button"
                        className={style.modalButton}
                        onClick={handleSaveNotes}
                    >
                        {t('garage.userGarage.noteModalConfirm')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default UserGarageDeleteModal
