import React from 'react'
import Equipment from '../../entities/Equipment'
import style from './recommendationRow.css'
import { Markup } from 'interweave'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import MediaImg from '../media-img'

type Props = {
    equipment: Equipment
    selectEquipment: Function
    openInfoModal: Function
}

const RecommendationRow: React.FC<Props> = ({ equipment, selectEquipment, openInfoModal }) => {
    const { id, name, media, price, selected, selectable, content, evaKey } = equipment
    const infoIsForced = media && media.find((mediaItem) => mediaItem.forced) !== undefined
    const changeHandler = (): void => {
        if (!selected) {
            selectEquipment(id)
        }
    }

    const infoButtonClicked = (): void => {
        openInfoModal({
            title: name,
            media,
            content,
        })
    }

    return (
        <section className={style.row}>
            <div className={style.detailsWrapper}>
                <div className={style.textAndImage}>
                        <span className={style.name}>
                            {name}
                            <span className={style.displayId}>{evaKey}</span>
                        </span>
                    {media && media.length > 0 && (
                        <div className={style.media}>
                            <MediaImg alt={name} media={media} width={100}/>
                        </div>
                    )}
                </div>
                <div className={style.footerWrapper}>
                    <div className={style.priceWrapper}>
                                    <span className={style.price}>
                <Markup content={price}/>
            </span>
                        <InfoButton openModal={infoButtonClicked} dataCy={`info-button-${id}`}>
                            <InfoI isForced={infoIsForced}/>
                        </InfoButton>
                    </div>
                    <button
                        onClick={changeHandler}
                        disabled={!selectable}
                        className={style.addButton}
                        data-cy={`add-button-${id}`}
                    >
                        Add
                    </button>
                </div>
            </div>
        </section>
    )
}

export default RecommendationRow
