import { createSelector } from 'reselect'
import { API_URL_CARLINE_FILTERS } from '../../../constants/apiUrls'
import pendingRequestsSelector from './pendingRequestsSelector'
import requestIsPending from './helpers/requestIsPending'

const carlineFiltersRequestIsPendingSelector = createSelector(
    pendingRequestsSelector,
    (pendingRequests): boolean => requestIsPending(pendingRequests, API_URL_CARLINE_FILTERS),
)

export default carlineFiltersRequestIsPendingSelector
