import React, { FunctionComponent } from 'react'
import { AppliedFilter } from '../../entities/Filter'
import style from './attributeFilters.css'
import FilterOption from '../../entities/FilterOption'
import RangeSlider from '../range-slider/RangeSlider'
import AttributeFiltersPlaceholder from './AttributeFiltersPlaceholder'
import CheckboxSelectDropdown from '../checkbox-select-dropdown/CheckboxSelectDropdown'
import getFilterTranslation from '../../helpers/getFilterTranslation'

export type Props = {
    filterOptions: FilterOption[]
    applyFilter: (filter: AppliedFilter) => void
    isLoading: boolean
    layout: 'vertical' | 'horizontal'
}

const AttributeFilters: FunctionComponent<Props> = (props) => {
    const {
        filterOptions,
        applyFilter,
        isLoading,
        layout,
    } = props

    const t = (key: string, params?: {}) => getFilterTranslation(key, params)

    const filterElements = (filterOptions.length === 0 && isLoading)
        ? <AttributeFiltersPlaceholder/>
        : filterOptions.map((filterOption) => {
            const {
                attribute,
                type,
                values,
                activeValues,
            } = filterOption

            const inputChangeHandler = (selectedOptions: any, attribute: string): void => {
                const selectedValues = selectedOptions ? selectedOptions.map((option: any) => option.value) : []
                const valuesToUpdate = selectedValues.filter((value: string | number) => !activeValues?.includes(value))
                const valuesToRemove = activeValues?.filter(value => !selectedValues.includes(value)) || []

                valuesToRemove.forEach(value => applyFilter({id: attribute, value, type: 'multiple'}))
                valuesToUpdate.forEach((value: string | number) => applyFilter({id: attribute, value, type: 'multiple'}))
            }

            if (type === 'multiple' && values.length >= 2) {
                const options = (values as string[]).map((value) => ({
                    value,
                    label: t(`attributes.valueLabels.${attribute}.${value}`),
                }))

                const placeholder = t(`filters.filterLabels.${attribute}`)

                return (
                    <div key={attribute}>
                        <CheckboxSelectDropdown
                            options={options}
                            onChange={(selectedOptions) => inputChangeHandler(selectedOptions, attribute)}
                            value={options.filter(option => activeValues?.includes(option.value))}
                            placeholder={placeholder}
                            activeValues={activeValues}
                        />
                    </div>
                )
            }

            if (type === 'max') {
                const initialValue = activeValues ? activeValues[0] as number : undefined

                return (
                    <label key={attribute}>
                        <span className={style.title}>{t(`filters.filterLabels.${attribute}-${type}`)}</span>
                        <RangeSlider
                            initial={initialValue}
                            values={values as number[]}
                            onChange={(selectedOptions) => applyFilter({
                                id: attribute,
                                value: selectedOptions,
                                type,
                            })}
                            debounceTime={200}
                            suffix={t(`filters.filterValueSuffix.${attribute}`)}
                            dataCy={`attribute-filter-${attribute}`}
                        />
                    </label>
                )
            }

            return null
        })

    const wrapperClassName = style[`wrapper-${layout}`]

    return (
        <div className={wrapperClassName}>
            {filterElements}
        </div>
    )
}

export default AttributeFilters
