import {createReducer} from 'typesafe-actions'
import CarlineFiltersData from '../../entities/CarlineFiltersData'
import { setCarlineFiltersDataState } from '../actions/app/carlineData.actions'

export type CarlineFiltersDataState = CarlineFiltersData

export const defaultState: CarlineFiltersDataState = {
    carlineGroups: [],
}

const carlineDataReducer = createReducer(defaultState)
    .handleAction(setCarlineFiltersDataState, (state, action) => action.payload)

export default carlineDataReducer
