import { createAction, createAsyncAction } from 'typesafe-actions'
import { ModelRecommendationsData } from '../../../entities/ModelRecommendationsData'
import { RecommendationsData } from '../../../entities/RecommendationsData'

export const fetchModelsWithRecommendations = createAction('CMD / RecommendationsData / fetch')()

export const fetchRecommendationsForModel = createAction('CMD / ModelRecommendationsData / fetch')()

export const fetchModelsWithRecommendationsAsync = createAsyncAction(
    'EVT / RecommendationsData / fetch request',
    'EVT / RecommendationsData / fetch success',
    'EVT / RecommendationsData / fetch failure',
)<undefined, RecommendationsData, Error>()

export const fetchRecommendationsForModelAsync = createAsyncAction(
    'EVT / ModelRecommendationsData / fetch request',
    'EVT / ModelRecommendationsData / fetch success',
    'EVT / ModelRecommendationsData / fetch failure',
)<undefined, ModelRecommendationsData, Error>()

export const setModelsWithRecommendationsData = createAction('DOC / RecommendationsData / set state')<RecommendationsData>()

export const setRecommendationsForModelData = createAction('DOC / ModelRecommendationsData / set state')<ModelRecommendationsData>()

export const resetRecommendationsForModelData = createAction('DOC / ModelRecommendationsData / reset state')()

export const resetModelsWithRecommendationsData  = createAction('DOC / RecommendationsData / reset state')()
