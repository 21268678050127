import { createSelector } from 'reselect'
import userGarageInfoSelector from './userGarageInfoSelector'
import Vehicle from '../../../entities/Vehicle'

const userGarageVehiclesSelector = createSelector(
    userGarageInfoSelector,
    (userGarageInfo): Vehicle[] => userGarageInfo.storedVehicles,
)

export default userGarageVehiclesSelector
