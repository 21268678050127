import React, { FunctionComponent } from 'react'
import { ConflictItem } from '../../entities/ConfigurationResponse'
import useTranslation from '../../hooks/useTranslation'
import Modal from '../modal'
import LoadingIndicatorWrapper from '../loading-indicator-wrapper'
import ForesightedBuildabilityConflictStep from './ForesightedBuildabilityConflictStep'
import style from './foresightedBuildabilityModal.css'
import ForesightedBuildabilityTriggerEquipment from './ForesightedBuildabilityTriggerEquipment'

export type Props = {
    conflictSteps: ConflictItem[][]
    isResolvable: boolean
    isCancelable: boolean
    total: string
    isLoading: boolean
    adoptConflictSolution: (InfoModalData) => void
    cancelConflictSolution: () => void
    openInfoModal: Function
    selectEquipment: Function
    deselectEquipment: Function
    isDisabled: boolean
    trigger: ConflictItem | null
}

const ForesightedBuildabilityModal: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation()
    const {
        conflictSteps,
        isResolvable,
        isCancelable,
        total,
        isLoading,
        adoptConflictSolution,
        cancelConflictSolution,
        openInfoModal,
        selectEquipment,
        deselectEquipment,
        isDisabled,
        trigger,
    } = props

    if (isDisabled) {
        return null
    }

    const conflictStepElements = conflictSteps.map((conflictStep) => (
        <ForesightedBuildabilityConflictStep
            key={conflictStep.find((item) => item.selected).equipment.id}
            conflictStep={conflictStep}
            openInfoModal={openInfoModal}
            selectEquipment={selectEquipment}
            deselectEquipment={deselectEquipment}
        />
    ))

    const notResolvableDisclaimerElement = !isResolvable ? (
        <p className={style.notResolvableDisclaimer}>
            {t('foresightedBuildabilityModal.notBuildableMsg')}
        </p>
    ) : null

    const selectedItem = conflictSteps
        .flatMap(conflictStep => conflictStep)
        .find(item => item.selected)?.equipment

    const isSelectedItemContainsForcedMedia = selectedItem.media && selectedItem.media.find((mediaItem) => mediaItem.forced) !== undefined

    const processAdoptConflictSolution = () => {
        if(isSelectedItemContainsForcedMedia) {
            const info = {
                title: selectedItem.name,
                media: selectedItem.media,
                content: selectedItem.content,
            }
            adoptConflictSolution(info)
        } else {
            adoptConflictSolution(undefined)
        }
    }

    const applyButtonElement = isResolvable ? (
        <li className={style.buttonListItem}>
            <button type="button" className={style.button} onClick={() => processAdoptConflictSolution()}>
                {t('foresightedBuildabilityModal.apply')}
            </button>
        </li>
    ) : null

    const cancelButtonElement = isCancelable ? (
        <li className={style.buttonListItem}>
            <button type="button" className={style.button} onClick={() => cancelConflictSolution()}>
                {t('foresightedBuildabilityModal.cancel')}
            </button>
        </li>
    ) : null

    return (
        <Modal isVisible={!isDisabled}>
            <LoadingIndicatorWrapper isLoading={isLoading}>
                <section className={style.container}>
                    <div className={style.conflictItemsWrapper}>
                        <h2>{t('foresightedBuildabilityModal.headline')}</h2>
                        <p className={style.subtitle}>
                            {t('foresightedBuildabilityModal.message2')}
                        </p>
                        <ForesightedBuildabilityTriggerEquipment conflictItem={trigger} />
                        {conflictStepElements}
                        <p className={style.sum}>
                            {t('foresightedBuildabilityModal.total')}
                            {' '}
                            {total}
                        </p>
                        {notResolvableDisclaimerElement}
                    </div>
                    <ul className={style.buttonList}>
                        {cancelButtonElement}
                        {applyButtonElement}
                    </ul>
                </section>
            </LoadingIndicatorWrapper>
        </Modal>
    )
}

export default ForesightedBuildabilityModal
