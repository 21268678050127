import React, { FunctionComponent, useState } from 'react'
import ArrowLeftIcon from '../../assets/media/icons/arrow-left.svg'
import ArrowRightIcon from '../../assets/media/icons/arrow-right.svg'
import style from './gallery.css'
import MiwiShowImage from '../miwi-show-image'

export type Props = {
    images: Pick<HTMLImageElement, 'src' | 'width' | 'height'>[]
    thumbnails: Pick<HTMLImageElement, 'src' | 'width' | 'height'>[]
    miwiImagesUrls: string[]
    activeImgIdx?: number
    forced?: boolean
}

const Gallery: FunctionComponent<Props> = (props) => {
    const {
        images,
        thumbnails,
        miwiImagesUrls,
        activeImgIdx: initialImgIdx,
        forced,
    } = props

    const [activeImgIdx, setActiveImageIdx] = useState(initialImgIdx || 0)
    const activeImg = images[activeImgIdx]
    const activeMiwiImgUrl = miwiImagesUrls[activeImgIdx]

    if (images.length === 0) {
        return null
    }

    if (images.length === 1) {
        return (
            <>
                <div className={style.container}>
                    <img src={images[0].src} className={style.img} alt="Audi" width={images[0].width} height={images[0].height} />
                </div>
                <MiwiShowImage imageUrl={activeMiwiImgUrl} />
            </>
        )
    }

    const thumbnailElements = thumbnails.map((image, index) => {
        const onClick = (): void => {
            setActiveImageIdx(index)
        }

        const buttonClassName = index === activeImgIdx
            ? style.thumbnailButtonActive
            : style.thumbnailButton

        return (
            <li key={image.src} className={style.thumbnailListItem}>
                <button onClick={onClick} className={buttonClassName} type="button">
                    <img src={image.src} className={style.img} alt="thumbnail" width={image.width} height={image.height} />
                </button>
            </li>
        )
    })

    const showNextImage = (): void => {
        const newIdx = activeImgIdx >= thumbnailElements.length - 1 ? 0 : activeImgIdx + 1

        setActiveImageIdx(newIdx)
    }

    const showPrevImage = (): void => {
        const newIdx = activeImgIdx === 0 ? thumbnailElements.length - 1 : activeImgIdx - 1

        setActiveImageIdx(newIdx)
    }

    const containerClasses = forced
        ? style.container + " " + style.containerForced
        : style.container

    return (
        <>
            <div className={containerClasses}>
                <div className={style.imgContainer}>
                    <img src={activeImg.src} className={style.img} width={activeImg.width} height={activeImg.height} alt="Audi" />
                    <ul className={style.sliderButtonList}>
                        <li>
                            <button onClick={showPrevImage} className={style.sliderButton} type="button">
                                <ArrowLeftIcon />
                            </button>
                        </li>
                        <li>
                            <button onClick={showNextImage} className={style.sliderButton} type="button">
                                <ArrowRightIcon />
                            </button>
                        </li>
                    </ul>
                </div>
                <ul className={style.thumbnailList}>
                    {thumbnailElements}
                </ul>
            </div>
            <MiwiShowImage imageUrl={activeMiwiImgUrl} />
        </>
    )
}

export default Gallery
